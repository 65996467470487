import styled from "@emotion/styled";
import { SpeedKind, getSpeedBackground } from "./resultUtils";
import RoundDiv from "./RoundDiv";
import {CompareTextContainer, SubTextContainer, SubTextDiv, SubTextDivGreySmall} from "./ProgressBarCommon";
import { theme } from "../../../../theme/theme";
import t from "../../../../helper/t";
import { formatSpeed } from "src/helper/formatSpeeds";

const RootDiv = styled.div([
    {
        marginTop: 32,
        display: "flex",
        flexDirection: "column",
    }
]);

const ProgressBackground = styled.div(({ theme }) => ([
    theme.splus3.background.monochrome3,
    {
        position: "relative",
        height: 6,
        borderRadius: 6,
    }
]));

interface ProgressVaueProps {
    speedKind: SpeedKind;
    positionFactor: number;
}

const ProgressValue = styled.div<ProgressVaueProps>(({ theme, speedKind, positionFactor }) => ([
    getSpeedBackground(theme.splus3, speedKind),
    {
        borderRadius: 6,
        width: `${100.0 * positionFactor}%`,
        height: "100%",
    }
]));

const ShiftFactor = 0.95;

interface IndicatorDivProps {
    top: number,
    positionFactor: number;
}

const IndicatorDiv = styled.div<IndicatorDivProps>(({ theme, top, positionFactor }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.font12,
    {
        top,
        left: `${100.0 * positionFactor * ShiftFactor}%`,
        width: 0,
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: 4,
        lineHeight: "100%",
    }
]));


const ResponsiveContainer = styled.div([
    {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        columnGap: 4,
    }
]);

interface IndicatorProps {
    speedKind: SpeedKind;
    currentValue: number;
    maxValue: number;
}

const Indicator: React.FC<IndicatorProps> = ({ speedKind, currentValue, maxValue }) => {
    return (
        <IndicatorDiv top={-31} positionFactor={currentValue / maxValue}>
            {formatSpeed([currentValue]).values[0]}
            <RoundDiv size={6} background={getSpeedBackground(theme.splus3, speedKind)} />
            <div style={{ width: 1, height: 11 }}>
                <svg width="1" height="11" viewBox="0 0 1 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1" height="4.00087" rx="0.5" fill="#0D0D0D" />
                    <rect y="6.00098" width="1" height="4.00087" rx="0.5" fill="#0D0D0D" />
                </svg>
            </div>
        </IndicatorDiv>
    );
}

interface ProgressBarProps {
    speedKind: SpeedKind;
    min: number;
    avg: number;
    max: number;
    current: number | null;
    showCompareText?: boolean;
    isHistoryView?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ speedKind, min, avg, max, current, showCompareText = true, isHistoryView = false }) => {
    const progressValue = Math.min(1.0, current === null ? 1.0 : current / max * ShiftFactor);
    return (
        <RootDiv data-cy="progressBar" role="img">
            <ProgressBackground>
                <ProgressValue speedKind={speedKind} positionFactor={progressValue} />
                <Indicator speedKind={SpeedKind.Minimal} currentValue={min} maxValue={max} />
                <Indicator speedKind={SpeedKind.Average} currentValue={avg} maxValue={max} />
                <Indicator speedKind={SpeedKind.Maximal} currentValue={max} maxValue={max} />
            </ProgressBackground>
            <SubTextContainer>
                <SubTextDiv historyView ={isHistoryView}>
                    <ResponsiveContainer>
                        <RoundDiv size={8} background={getSpeedBackground(theme.splus3, SpeedKind.Minimal)}/>
                        {t("Minimal")}
                    </ResponsiveContainer>
                    <SubTextDivGreySmall historyView ={isHistoryView} data-cy="minSpeed">{"(" + formatSpeed([min]).values[0] + ")"}</SubTextDivGreySmall>
                </SubTextDiv>
                <SubTextDiv historyView ={isHistoryView}>
                    <ResponsiveContainer>
                        <RoundDiv size={8} background={getSpeedBackground(theme.splus3, SpeedKind.Average)} />
                        {t("Üblich")}
                    </ResponsiveContainer>
                    <SubTextDivGreySmall historyView ={isHistoryView} data-cy="avgSpeed">{"(" + formatSpeed([avg]).values[0] + ")"}</SubTextDivGreySmall>
                </SubTextDiv>
                <SubTextDiv historyView ={isHistoryView}>
                    <ResponsiveContainer>
                        <RoundDiv size={8} background={getSpeedBackground(theme.splus3, SpeedKind.Maximal)} />
                        {t("Maximal")}
                    </ResponsiveContainer>
                    <SubTextDivGreySmall historyView ={isHistoryView} data-cy="maxSpeed">{"(" + formatSpeed([max]).values[0] + ")"}</SubTextDivGreySmall>
                </SubTextDiv>
            </SubTextContainer>
            {showCompareText &&
                <CompareTextContainer>
                    <span>{t("schlechter")}</span>
                    <span>{t("besser")}</span>
                </CompareTextContainer>
            }
        </RootDiv>
    );
}

export default ProgressBar;