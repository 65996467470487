import styled from "@emotion/styled";
import { useState } from "react";
import CheckBoxBoolean from "../../elements/CheckBoxBoolean";
import ComboBox from "../../elements/ComboBox";
import { NQRecord } from "../../../../features/history/types";
import { ModemFinalKind } from "@visonum/network-quality-sdk";
import * as R from "ramda";
import { devAssert } from "../../../../features/errors/devAssert";
import { AppAssertionError } from "../../../../features/errors/AppAssertionError";
import { theme } from "../../../../theme/theme";
import { getRouterSpeed } from "../../../../helper/utils";
import { formatDate } from "../../../../helper/unitsFormatting";
import NoDataStub from "../../elements/NoDataStub";
import { useAppSelector } from "../../../../hooks";
import { getAllRecords } from "../../../../features/history/getAllRecords";
import t from "../../../../helper/t";
import LanguageKey from "../../../../../generated/types/LanguageKey";
import { PlainDate, plainDate, plainDateGetFullYear, plainDateGetMonth } from "../../../../types";
import { formatSpeed } from "../../../../helper/formatSpeeds";

const ChartItemHeight = 286;

const RootDiv = styled.div(({ theme }) => ([
    theme.splus3.background.primary,
    {
        display: "flex",
        flexDirection: "column",
        rowGap: 16,
    }
]));

const ToolbarDesktopDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        columnGap: 16,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "none",
            }
        ],
    }
]));

const ToolbarMobileDiv = styled.div(({ theme }) => ([
    {
        display: "none",
        columnGap: 16,
        flexWrap: "wrap",
        paddingLeft: 10,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "flex",
            }
        ],
    }
]));

const FilterContainerDiv = styled.div([
    {
        display: "flex",
        gap: 16,
        alignItems: "center",
    }
]);

const LabelDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.small,
    {
        display: "flex",
        gap: 8,
        alignItems: "center",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font12,
            {
            }
        ],
    }
]));

const ChartCard = styled.div([
    {
        display: "grid",
        gridTemplateColumns: "auto auto 1fr",
        gridTemplateRows: `5px ${ChartItemHeight}px 5px auto`,
        paddingTop: 24,
        paddingBottom: 24,
        columnGap: 8,
    }
]);

const VerticalLabelDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.font12Bold,
    {
        gridColumn: 1,
        gridRow: 2,
        alignSelf: "center",
        display: "flex",
        alignItems: "center",
        width: 24,
        writingMode: "vertical-lr",
        transform: "rotate(180deg)",
    }
]));

const YAxisDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.font12,
    {
        gridColumn: 2,
        gridRowStart: 1,
        gridRowEnd: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font10,
        ],
    }
]));

const ChartGrid = styled.div([
    {
        gridColumn: 3,
        gridRow: 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    }
]);

const ChartArea = styled.div([
    {
        gridColumn: 3,
        gridRowStart: 2,
        gridRowEnd: 5,

        display: "flex",
        columnGap: 16,
        overflowX: "auto",
    }
]);

interface FilterDataItem {
    captionKey: LanguageKey,
    filter: (records: NQRecord[], currentDay: PlainDate) => NQRecord[];
}

const filterData: FilterDataItem[] = [
    {
        captionKey: "Letzte 7 Tage", // Last 7 days
        filter: (records, currentDay) => 
            records.filter(record => (currentDay.time - record.date.time) / (1000 * 3600 * 24) <= 7),
    },
    {
        captionKey: "In diesem Monat", // This month
        filter: (records, currentDay) => 
            records.filter(record => plainDateGetMonth(currentDay) == plainDateGetMonth(record.date) && plainDateGetFullYear(currentDay) == plainDateGetFullYear(record.date)),
    },
    {
        captionKey: "In diesem Jahr", // This year
        filter: (records, currentDay) => 
            records.filter(record => plainDateGetFullYear(currentDay) == plainDateGetFullYear(record.date)),
    },
]

interface HistoryChartProps {
}

const HistoryChart: React.FC<HistoryChartProps> = ({ }) => {
    const historyState = useAppSelector(state => state.history);
    const sortedRecords = getAllRecords(historyState);

    const [showRouterDownload, setShowRouterDownload] = useState(true);
    const [showDownload, setShowDownload] = useState(true);
    const [showUpload, setShowUpload] = useState(true);
    const [filterIndex, setFilterIndex] = useState(filterData.length - 1);

    const filteredRecords = filterData[filterIndex]?.filter(sortedRecords, plainDate()) ?? [];

    const routerSpeedValues = showRouterDownload ?
        (filteredRecords.map(r => r.modemFinal.modemFinal.kind === ModemFinalKind.ModemFinalAvailable ? r.modemFinal.modemFinal.speed : null)) :
        [0];
    const downloadSpeedValues = showDownload ? (filteredRecords.map(r => r.downloadFinal.speed)) : [0];
    const uploadSpeedValues = showUpload ? (filteredRecords.map(r => r.uploadFinal.speed)) : [0];

    const routerSpeedMax = R.apply(Math.max, routerSpeedValues.filter(x => x !== null).map(x => x!));
    const downloadSpeedMax = R.apply(Math.max, downloadSpeedValues);
    const uploadSpeedMax = R.apply(Math.max, uploadSpeedValues);

    const absMax = Math.round(R.apply(Math.max, [routerSpeedMax, downloadSpeedMax, uploadSpeedMax]));

    const yAxisValues = [0, Math.round(absMax / 3), Math.round(absMax / 3 * 2), absMax];

    const speedInfo = formatSpeed([routerSpeedMax, downloadSpeedMax, uploadSpeedMax]);

    return (
        <RootDiv data-cy="historyChart" role="img">
            <ToolbarDesktopDiv>
                <FilterContainerDiv>
                    <CheckBoxBoolean checked={showRouterDownload} onClick={() => setShowRouterDownload(!showRouterDownload)}/>
                    <LabelDiv>
                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5.5" cy="5" r="5" fill={theme.splus3.colors.chartColorRouterDownload} />
                        </svg>
                        <span>{t("Download zum Router")}</span>
                    </LabelDiv>

                    <CheckBoxBoolean checked={showDownload} onClick={() => setShowDownload(!showDownload)}/>
                    <LabelDiv>
                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5.5" cy="5" r="5" fill={theme.splus3.colors.chartColorDownload} />
                        </svg>
                        <span>{t("Download zum Gerät")}</span>
                    </LabelDiv>

                    <CheckBoxBoolean checked={showUpload} onClick={() => setShowUpload(!showUpload)}/>
                    <LabelDiv>
                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5.5" cy="5" r="5" fill={theme.splus3.colors.chartColorUpload} />
                        </svg>
                        <span>{t("Upload vom Gerät")}</span>
                    </LabelDiv>
                </FilterContainerDiv>

                <ComboBox transparent={false}
                    items={filterData.map(x => t(x.captionKey))}
                    selectedIndex={filterIndex}
                    onChangeSelectedIndex={(newValue) => setFilterIndex(newValue)} />
            </ToolbarDesktopDiv>

            <ToolbarMobileDiv>
                <LabelDiv>
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5.5" cy="5" r="5" fill={theme.splus3.colors.chartColorRouterDownload} data-cy="routerCircle"/>
                    </svg>
                    <span>{t("Download")} ({t("Router")})</span>
                </LabelDiv>

                <LabelDiv>
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5.5" cy="5" r="5" fill={theme.splus3.colors.chartColorDownload} data-cy="downloadCircle"/>
                    </svg>
                    <span>{t("Download")} ({t("Gerät")})</span>
                </LabelDiv>

                <LabelDiv>
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5.5" cy="5" r="5" fill={theme.splus3.colors.chartColorUpload} data-cy="uploadCircle"/>
                    </svg>
                    <span>{t("Upload")} ({t("Gerät")})</span>
                </LabelDiv>
            </ToolbarMobileDiv>

            <ChartCard>
                {(showRouterDownload || showDownload || showUpload) && filteredRecords.length > 0 ? (
                    <>
                        <VerticalLabelDiv>{speedInfo.unit}</VerticalLabelDiv>
                        <YAxisDiv data-cy="yAxisHistoryChart">
                            {yAxisValues.reverse().map((value, index) =>
                                <span key={index}>{formatSpeed([], value).value}</span>
                            )}
                        </YAxisDiv>
                        <ChartGrid>
                            {yAxisValues.reverse().map((_, index) =>
                                <svg key={index} width="100%" height="2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="0" y1="1" x2="100%" y2="1" stroke="#0D0D0D" strokeLinecap="square" strokeDasharray="1 4" />
                                </svg>
                            )}
                        </ChartGrid>
                        <ChartArea tabIndex={0}>
                            {filteredRecords.map((record, index) =>
                                <HistoryChartItem key={index}
                                    record={record}
                                    showRouterDownload={showRouterDownload}
                                    showDownload={showDownload}
                                    showUpload={showUpload}
                                    maxSpeed={absMax} />
                            )}
                        </ChartArea>
                    </>
                ) : (
                    <div style={{ gridRow: 1, gridColumn: 3 }}>
                        <NoDataStub message={t("Es sind keine Messungen vorhanden")} />
                    </div>
                )}
            </ChartCard>
        </RootDiv>
    );
}

const ItemRootDiv = styled.div({
    display: "flex",
    flexDirection: "column",
});

const ItemContainerDiv = styled.div({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-around",
    height: ChartItemHeight - 2,
    marginTop: 1,
    marginBottom: 1,
});

interface SpeedBarBaseProps {
    speed: number | null;
    maxSpeed: number;
}

const barHeight = (maxSpeed: number, speed: number): string => {
    if (maxSpeed <= 0) {
        devAssert(new AppAssertionError({ actual: maxSpeed, expected: "maxSpeed > 0" }), "barHeight(): maxSpeed should be above 0");
        return "0";
    }

    return `${speed / maxSpeed * 100}%`;
}

const SpeedBarBase = styled.div<SpeedBarBaseProps>(({ maxSpeed, speed }) => ([
    {
        width: 16,
        height: speed === null ? 0 : barHeight(maxSpeed, speed),
        opacity: speed === null ? 0 : 1,
        borderRadius: "8px 8px 0 0",
        background: "red",
    }
]));

const SpeedBarRouterDownload = styled(SpeedBarBase)(({ theme }) => ([
    theme.splus3.background.chartBarRouterDownload,
]));

const SpeedBarDownload = styled(SpeedBarBase)(({ theme }) => ([
    theme.splus3.background.chartBarDownload,
]));

const SpeedBarUpload = styled(SpeedBarBase)(({ theme }) => ([
    theme.splus3.background.chartBarUpload,
]));


const DayLabelDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.small,
    {
        height: 24,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font12,
        ],
    }
]));
interface HistoryChartItemProps {
    record: NQRecord;
    showRouterDownload: boolean;
    showDownload: boolean;
    showUpload: boolean;
    maxSpeed: number;
}

const HistoryChartItem: React.FC<HistoryChartItemProps> = ({ record, showRouterDownload, showDownload, showUpload, maxSpeed }) => {
    return (
        <ItemRootDiv>
            <ItemContainerDiv>
                {showRouterDownload && <SpeedBarRouterDownload maxSpeed={maxSpeed} speed={getRouterSpeed(record.modemFinal)} data-cy="routerBar"/>}
                {showDownload && <SpeedBarDownload maxSpeed={maxSpeed} speed={record.downloadFinal.speed} data-cy="downloadBar"/>}
                {showUpload && <SpeedBarUpload maxSpeed={maxSpeed} speed={record.uploadFinal.speed} data-cy="uploadBar"/>}
            </ItemContainerDiv>
            <DayLabelDiv>
                {formatDate(record.date)}
            </DayLabelDiv>
        </ItemRootDiv>
    );
}

export default HistoryChart;
