/**
 * Removes the vendor from the deviceName if it appears in the model name once again as a separate word.
 * If the vendor is part of another word (e.g., surrounded by `-` or inside a word), it keeps it.
 */
export const concatVendorAndModel = (vendor: string, model: string): string => {
    const vendorPattern = new RegExp(`(^| )${vendor}($| )`);
    if (vendorPattern.test(model)) {
        return model
    }
    return `${vendor} ${model}`
}