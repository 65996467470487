import styled from "@emotion/styled";
import { HeaderRow, TableRow } from "./TableRowDesktop";
import { CheckedType } from "../../elements/CheckBox";
import HistoryRecordDetailsDesktop from "./HistoryRecordDetailsDesktop";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { updateExpandedRecord, updateSelectedRecords } from "../../../../features/history/historySlice";
import { getAllRecords } from "../../../../features/history/getAllRecords";
import { NQRecord } from "../../../../features/history/types";
import { showRemoveHistoryDialog } from "../../../../features/dialogManager/dialogManagerSlice";

const RootDiv = styled.div(({ theme }) => ([
    theme.splus3.background.primary,
    {
        display: "flex",
        flexDirection: "column",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "none",
            }
        ],
    }
]));

const TableContainer = styled.div(
    {
        display: "flex",
        flexDirection: "column",

        maxHeight: 400,
        overflowY: "hidden",
        ":hover": {
            overflowY: "auto",
        },
    }
);

interface HistoryTableProps {
}

const HistoryTableDesktop: React.FC<HistoryTableProps> = ({ }) => {
    const dispatch = useAppDispatch();

    const historyState = useAppSelector(state => state.history);
    const sortedRecords = getAllRecords(historyState);

    const allChecked = ((): CheckedType => {
        if (historyState.selectedRecords.length === 0) {
            return false;
        } else if (historyState.selectedRecords.length === sortedRecords.length) {
            return true;
        } else {
            return "indeterminate";
        }
    })();

    const headerCheckedClick = () => {
        switch (allChecked) {
            case false:
            case "indeterminate":
                dispatch(updateSelectedRecords(sortedRecords));
                break;
            case true:
                dispatch(updateSelectedRecords([]));
                break;
        }
    }

    const onCheck = (record: NQRecord) => {
        const newSelectedRecords = historyState.selectedRecords.includes(record) ?
            historyState.selectedRecords.filter(r => r != record) :
            [...historyState.selectedRecords, record];
        dispatch(updateSelectedRecords(newSelectedRecords));
    }

    const onExpand = (record: NQRecord) => {
        dispatch(updateExpandedRecord(historyState.expandedRecord === record ? null : record));
    }

    const deleteAllEnabled = historyState.selectedRecords.length > 0;
    const deleteAll = () => {
        dispatch(showRemoveHistoryDialog())
    }

    return (
        <RootDiv role="table">
            <HeaderRow records={sortedRecords} checked={allChecked} onCheckedClick={headerCheckedClick} />
            <TableContainer>
                {sortedRecords.map((record, index) => {
                    return <TableRow key={index}
                        records={sortedRecords}
                        record={record}
                        isChecked={historyState.selectedRecords.includes(record)}
                        isExpanded={historyState.expandedRecord === record}
                        onCheck={() => onCheck(record)}
                        onExpand={() => onExpand(record)}>
                        <HistoryRecordDetailsDesktop records={sortedRecords} record={record} />
                    </TableRow>
                })}
            </TableContainer>
        </RootDiv>
    );
}

export default HistoryTableDesktop;
