import styled from "@emotion/styled";
import HistoryTableDesktop from "./HistoryTableDesktop";
import HistoryChart from "./HistoryChart";
import NavigationButton from "../../elements/NavigationButton";
import { CommonButtonDiv } from "../../elements/Buttons";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import NoDataStub from "../../elements/NoDataStub";
import HistoryTableMobile from "./HistoryTableMobile";
import t from "../../../../helper/t";
import { startMeasurement3 } from "../../measurement/startMeasurement3";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }
]));

const BaseContainer = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% - 40px)",
        paddingLeft: 20,
        paddingRight: 20,
        maxWidth: "min(100%, 1440px)",
    }
]));

const TableContainer = styled(BaseContainer)(({ theme }) => ([
    theme.splus3.background.primary,
    {
        paddingTop: 24,
        paddingBottom: 24,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                width: "100%",
                paddingLeft: 0,
                paddingRight: 0,
            }
        ],
    }
]));

const CaptionContainer = styled(BaseContainer)(({ theme }) => ([
    {
        width: "calc(100% - 44px)",
        marginTop: 40,
        paddingLeft: 24,
        paddingRight: 20,
        paddingTop: 24,
        paddingBottom: 16,
    }
]));

const CaptionDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.text,
    theme.splus3.typography.font24Bold,
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                marginTop: 0,
            }
        ],
    }
]));

const ChartContainer = styled(BaseContainer)(({ theme }) => ([
    theme.splus3.background.primary,

    {
        paddingTop: 16,
        paddingBottom: 32,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                paddingLeft: 12,
                paddingRight: 20,
            }
        ],
    }
]));

const ButtonContainer = styled.div(({ theme }) => ([
    {
        marginTop: 24,
        marginBottom: 48,
        marginLeft: "auto",
        marginRight: "auto",
    }
]));

export const StartButtonDiv = styled(CommonButtonDiv)(
    {
        paddingLeft: 30,
        paddingRight: 30,
        minWidth: 0,
    }
);

const WhiteContainer = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.background.transparent,
        ],
    }
]));

interface HistoryProps {
}

const History: React.FC<HistoryProps> = ({ }) => {
    const dispatch = useAppDispatch();
    const historyState = useAppSelector(state => state.history);

    return (
        <RootDiv>
            {historyState.records.length === 0 ?
                <NoDataStub message={t("Es sind keine Messungen vorhanden")} /> :
                <>
                    <TableContainer>
                        <HistoryTableDesktop />
                        <HistoryTableMobile />
                    </TableContainer>
                    <WhiteContainer>
                        <CaptionContainer>
                            <CaptionDiv role="heading" aria-level={2}>{t("Vergleich der Testergebnisse")}</CaptionDiv>
                        </CaptionContainer>
                    </WhiteContainer>
                    <ChartContainer>
                        <HistoryChart />
                    </ChartContainer>
                </>
            }
            <WhiteContainer style={{ display: "flex", flexDirection: "column" }}>
                <ButtonContainer>
                    <NavigationButton onClick={() => {
                        dispatch(startMeasurement3());
                    }}>
                        <StartButtonDiv>
                            {t("Speedtest erneut starten")}
                        </StartButtonDiv>
                    </NavigationButton>
                </ButtonContainer>
            </WhiteContainer>
        </RootDiv>
    );
}

export default History;
