import styled from "@emotion/styled";
import * as R from "ramda";
import CheckBox, { CheckedType } from "../../elements/CheckBox";
import { formatDate, formatPingTime, formatTime } from "../../../../helper/unitsFormatting";
import { ButtonDiv, CheckBoxContainer, ExpanderContainer, FirstCellDiv, RowDivStyleDesktop } from "./elements";
import { NQRecord } from "../../../../features/history/types";
import { historyRecordSpeedUnits, historyRecordSpeedValues } from "./historyTableData";
import { CellDiv, RowContainer, TableDivider } from "./TableRowCommon";
import t from "../../../../helper/t";
import { ClickableDiv } from "../../elements/ClickableDiv";
import getSpeedtestId from "../../../../helper/utils/getSpeedtestId";
import NavigationButton from "../../elements/NavigationButton";
import { showRemoveHistoryDialog } from "../../../../features/dialogManager/dialogManagerSlice";
import { useAppDispatch } from "../../../../hooks";

import deleteSvg from "../../../../../assets/icons/splus3/delete.svg";

interface RowDivProps {
    isSelected: boolean;
}

const RowDiv = styled.div<RowDivProps>(({ theme, isSelected }) => ([
    isSelected ? theme.splus3.background.monochrome3 : { background: "" },
    RowDivStyleDesktop
]));

const LastCell = styled.div([{
    display: "flex",
    // background: "cyan",
}]);

const ButtonsContainer = styled.div(
    {
        display: "flex",
        justifyContent: "flex-end",
        columnGap: 8,
    }
);

interface CellProps {
    title: string,
    sub?: string,
}

interface HeaderRowProps {
    records: NQRecord[],
    checked: CheckedType;
    onCheckedClick: () => void;
}

export const HeaderRow: React.FC<HeaderRowProps> = ({ records, checked, onCheckedClick }) => {
    const dispatch = useAppDispatch();
    
    const units = historyRecordSpeedUnits(records);

    const headerValues: CellProps[] = [
        { title: t("Datum"), sub: t("Speedtest-ID") },
        { title: `${t("Download")} (${units.routerSpeedUnit})`, sub: t("Zum Router") },
        { title: `${t("Download")} (${units.downloadSpeedUnit})`, sub: t("Zum Gerät") },
        { title: `${t("Upload")} (${units.uploadSpeedUnit})`, sub: t("Vom Gerät") },
        { title: `${t("Reaktionszeit")} (${t("ms")})`, sub: t("Ping") },
    ];

    const cell = (v: CellProps, index?: number) => {
        return <CellDiv key={index} role="columnheader">
            <strong>{v.title}</strong>
            {!R.isNil(v.sub) && (
                <span>{v.sub}</span>
            )}
        </CellDiv>
    }

    const deleteAllEnabled = checked != false;
    const deleteAll = () => {
        dispatch(showRemoveHistoryDialog())
    }

    const cellWrapper = (v: CellProps, index: number) => {
        if (index < headerValues.length - 1) {
            return cell(v, index);
        } else {
            return <LastCell key={index}>
                {cell(v)}
                <ButtonsContainer>
                    <NavigationButton onClick={deleteAll} isEnabled={deleteAllEnabled}>
                        <ButtonDiv isEnabled={deleteAllEnabled} role="cell">
                            {/* {t("Löschen")} */}
                            <img src={deleteSvg.src} alt={t("Alle löschen")} width={24} height={24} />
                        </ButtonDiv>
                    </NavigationButton>
                </ButtonsContainer>
            </LastCell>
        }
    }

    return (
        <RowContainer>
            <RowDiv isSelected={false} role="row">
                <FirstCellDiv role="cell">
                    <CheckBoxContainer>
                        <CheckBox checked={checked} onClick={onCheckedClick} />
                    </CheckBoxContainer>
                </FirstCellDiv>
                {headerValues.map(cellWrapper)}
            </RowDiv>
            <TableDivider />
        </RowContainer>
    );
}

interface ContentDivProps {
    isExpanded: boolean;
}

const ContentDiv = styled.div<ContentDivProps>(({ theme, isExpanded }) => ([
    {
        overflow: "hidden",
        transition: "max-height 0.2s ease-out",
        maxHeight: isExpanded ? 2000 : 0,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            marginTop: isExpanded ? 12 : 0,
        },
    },
]));

interface TableRowProps {
    records: NQRecord[];
    record: NQRecord;
    isExpanded: boolean;
    isChecked: boolean;
    onCheck: () => void;
    onExpand: () => void;
}

export const TableRow: React.FC<TableRowProps> = ({ records, record, isExpanded, isChecked, onCheck, onExpand, children }) => {
    const values = historyRecordSpeedValues(records, record);

    return (
        <RowContainer data-cy="historyRowDesktop">
            <RowDiv isSelected={isChecked || isExpanded} role="row">
                <FirstCellDiv role="cell">
                    <ExpanderContainer>
                        <ClickableDiv alt="details" dataCy="history-row-expander" onClick={onExpand} ariaLabel={isExpanded ? "Collapse content" : "Expand content"} ariaIsExpanded={isExpanded} ariaControls="historyRowContent">
                            {isExpanded ?
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 1L7 7L1 0.999999" stroke="#0D0D0D" strokeMiterlimit="10" strokeLinecap="round" />
                                </svg>
                                :
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L7 7L1 13" stroke="#0D0D0D" strokeMiterlimit="10" strokeLinecap="round" />
                                </svg>
                            }
                        </ClickableDiv>
                    </ExpanderContainer>

                    <CheckBoxContainer data-cy="historyCheckBoxDesktop">
                        <CheckBox checked={isChecked} onClick={onCheck}/>
                    </CheckBoxContainer>
                </FirstCellDiv>

                <CellDiv role="rowheader">
                    {formatDate(record.date)} - {formatTime(record.date)}
                    <span>{getSpeedtestId(record.prepareResult)}</span>
                </CellDiv>
                <CellDiv data-cy="routerPrevDesktop" role="cell">
                    {values.routerSpeedStr ?? "--"}
                </CellDiv>
                <CellDiv data-cy="downloadPrevDesktop" role="cell">
                    {values.downloadSpeedStr}
                </CellDiv>
                <CellDiv data-cy="uploadPrevDesktop" role="cell">
                    {values.uploadSpeedStr}
                </CellDiv>
                <CellDiv data-cy="pingPrevDesktop" role="cell">
                    {formatPingTime(record.pingFinal.pingTime)}
                </CellDiv>
            </RowDiv>
            <TableDivider />
            <ContentDiv isExpanded={isExpanded} id="historyRowContent">
                {isExpanded && children}
            </ContentDiv>
        </RowContainer>
    );
}