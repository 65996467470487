import styled from "@emotion/styled";

export const SubTextContainer = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome500,
    theme.splus3.typography.smallBold,
    {
        display: "flex",
        marginTop: 8,
        columnGap: 12,
    }
]));

export const SubTextDiv = styled.div<{ historyView?: boolean }>(({ historyView = false }) => ({
        display: "flex",
        columnGap: historyView ? 2 : 4,
        alignItems: "center",
        fontSize: historyView ? "12px" : "inherit",
        flexDirection: historyView? "column" : "row",
}));

export const SubTextDivGreySmall = styled.div<{ historyView?: boolean }>(({ theme, historyView = false }) => ([
    theme.splus3.foreground.monochrome4,
    theme.splus3.foreground.monochrome4,
    theme.splus3.typography.small,
    {
        display: "flex",
        columnGap: historyView ? 2 : 4,
        alignItems: "center",
        fontSize: historyView ? "12px" : "inherit",
    }
]));

export const CompareTextContainer = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome4,
    theme.splus3.typography.small,
    {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 8,
        lineHeight: "100%",

        [theme.splus3.breakpoints.mobile]: [
            {
                marginTop: 22,
            }
        ],        
    }
]));